import { BaseDto } from "./base-dto";
export class DietaDto extends BaseDto {
  public energia !: number;
  public glucidos_totales !: string;
  public polisacaridos !: string;
  public azucares !: string;
  public fibra !: string;
  public grasas_totales !: string;
  public agmi !: string;
  public agpi !: string;
  public ags !: string;
  public colesterol !: string;
  public proteinas_totales !: string;
  public proteinas_animales !: string;
  public proteinas_vegetales !: string;
  public total !: string;
  public porcen_hc !: string;
  public porcen_gr !: string;
  public porcen_pr !: string;
  public id_dieta !: string;
  public fecha !: string;
}
